$(function(){
	const Shuffle = window.Shuffle;
	const container = document.querySelector('.shuffle-container');
	const sizer = container.querySelector('.cards__sizer');

	const shuffleInstance = new Shuffle(container, {
		itemSelector: '.cards__item',
		sizer: sizer
	});

	$(document).on('change', '.js-tab input', function(){
		const filterName = $(this).attr('value')
		if (filterName === 'ВСЕ') {
			shuffleInstance.filter()
		} else {
			shuffleInstance.filter(filterName);
		}
	})

	$(document).on('change', '.js-sort input', function(){

		const value = $(this).attr('value')

		const sortByCount = (element) => element.getAttribute('data-count')
		const sortByTitle = (element) => element.getAttribute('data-title').toLowerCase()

		let options;
		if (value === 'count') {
			options = {
				reverse: true,
				by: sortByCount,
			};
		} else if (value === 'title') {
			options = {
				by: sortByTitle,
			};
		} else {
			options = {};
		}

		shuffleInstance.sort(options);
	})
})